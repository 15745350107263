import React from 'react';
import Layout from '../layouts/layout';

import { Banner } from '../components';

import banner from '../assets/img/banner-test.jpg';

const NotFoundPage = () => (
  <Layout>
    <Banner imgSrc={banner}>
      <div className="container">
        <div className="row mb-24">
          <div
            className="col-12 d-flex flex-column justify-content-center align-items-center text-center"
            data-aos="zoom-in"
          >
            <h1 className="h1 text-color-white">STRÁNKA SA NENAŠLA 404</h1>
            <p className="h2 text-color-white">
              Požadovanú stránku sa nepodarilo nájsť.
            </p>
          </div>
        </div>
      </div>
    </Banner>
  </Layout>
);

export default NotFoundPage;
